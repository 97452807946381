@import url('https://fonts.googleapis.com/css?family=Montserrat|PT+Mono');

$font-style: Montserrat, 'Helvetica Neue', Arial;
$font-style2: 'PT Mono', monospace;

$font-heading-x-large: 2.375em;
$font-heading-large: 2.25em;
$font-heading-medium: 2.125em;
$font-heading-small: 2em;

$font-title: 1.75em;
$font-label: 1em;
$font-subLabel: 0.85em;
$font-body: 1em;
$font-caption: 0.75em;

$font-weight-heavy: 700;
$font-weight-medium: 600;
$font-weight-normal: 500;
$font-weight-light: 400;
$font-weight-extra-light: 200;
