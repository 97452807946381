@import url(https://fonts.googleapis.com/css?family=Montserrat|PT+Mono);
@import url(https://fonts.googleapis.com/css?family=Montserrat|PT+Mono);
@import url(https://fonts.googleapis.com/css?family=Montserrat|PT+Mono);
@import url(https://fonts.googleapis.com/css?family=Montserrat|PT+Mono);
.styledized-list-headers {
  margin: 1em 0; }

/* GLOBAL RESETS */
i {
  font-style: normal; }

h1,
h2,
h3 {
  margin: 0; }

:focus {
  outline: none; }

/* GLOBAL RESETs ENDS*/
.dark {
  background: #000000;
  color: white !important; }
  .dark:hover {
    background: #4d4d4d; }

.light {
  background: #ffffff;
  color: white; }
  .light:hover {
    background: #e6e6e6; }

.primary {
  background: #0000ff;
  color: white; }
  .primary:hover {
    background: #000abf; }

.secondary {
  background: #606060;
  color: white; }
  .secondary:hover {
    background: #6e6e6e; }

.info {
  background: #00a9bf;
  color: white; }
  .info:hover {
    background: #00cbe6; }

.success {
  background: #13bf00;
  color: white; }
  .success:hover {
    background: #17e600; }

.warning {
  background: #fff000;
  color: white; }
  .warning:hover {
    background: #e6e600; }

.danger {
  background: #bf1000;
  color: white; }
  .danger:hover {
    background: #e61300; }

.dark-outline {
  border: 2px solid #000000;
  background: white;
  color: #000000; }
  .dark-outline:hover {
    background: #000000;
    color: white; }

.light-outline {
  border: 2px solid #ffffff;
  background: white;
  color: #ffffff; }
  .light-outline:hover {
    background: #ffffff;
    color: white; }

.primary-outline {
  border: 2px solid #0000ff;
  background: white;
  color: #0000ff; }
  .primary-outline:hover {
    background: #0000ff;
    color: white; }

.secondary-outline {
  border: 2px solid #606060;
  background: white;
  color: #606060; }
  .secondary-outline:hover {
    background: #606060;
    color: white; }

.info-outline {
  border: 2px solid #00a9bf;
  background: white;
  color: #00a9bf; }
  .info-outline:hover {
    background: #00a9bf;
    color: white; }

.success-outline {
  border: 2px solid #13bf00;
  background: white;
  color: #13bf00; }
  .success-outline:hover {
    background: #13bf00;
    color: white; }

.warning-outline {
  border: 2px solid #fff000;
  background: white;
  color: #fff000; }
  .warning-outline:hover {
    background: #fff000;
    color: white; }

.danger-outline {
  border: 2px solid #bf1000;
  background: white;
  color: #bf1000; }
  .danger-outline:hover {
    background: #bf1000;
    color: white; }

/** text styles  */
/** colors **/
.text-primary-color {
  color: #0000ff; }
  .text-primary-color:hover {
    color: #000abf; }

.text-primary-dark-color {
  color: #000680; }
  .text-primary-dark-color:hover {
    color: #000abf; }

.text-primary-light-color {
  color: #000be6; }
  .text-primary-light-color:hover {
    color: #000abf; }

.text-info-color {
  color: #00a9bf; }
  .text-info-color:hover {
    color: #00cbe6; }

.text-info-dark-color {
  color: #007180; }
  .text-info-dark-color:hover {
    color: #00cbe6; }

.text-info-light-color {
  color: #00e1ff; }
  .text-info-light-color:hover {
    color: #00cbe6; }

.text-success-color {
  color: #13bf00; }
  .text-success-color:hover {
    color: #17e600; }

.text-success-dark-color {
  color: #0d8000; }
  .text-success-dark-color:hover {
    color: #17e600; }

.text-success-light-color {
  color: #1bff00; }
  .text-success-light-color:hover {
    color: #17e600; }

.text-warning-color {
  color: #fff000; }
  .text-warning-color:hover {
    color: #e6e600; }

.text-warning-dark-color {
  color: #808000; }
  .text-warning-dark-color:hover {
    color: #e6e600; }

.text-warning-light-color {
  color: #fff000; }
  .text-warning-light-color:hover {
    color: #e6e600; }

.text-danger-color {
  color: #bf1000; }
  .text-danger-color:hover {
    color: #e61300; }

.text-danger-dark-color {
  color: #800b00; }
  .text-danger-dark-color:hover {
    color: #e61300; }

.text-danger-light-color {
  color: #ff1400; }
  .text-danger-light-color:hover {
    color: #e61300; }

.text-black-color {
  color: #000; }
  .text-black-color:hover {
    color: #6a6a6a; }

.text-white-color {
  color: #fff; }
  .text-white-color:hover {
    color: #f0f0f0; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-heavy {
  font-weight: 700; }

.text-medium {
  font-weight: 600; }

.text-normal {
  font-weight: 500; }

.text-light {
  font-weight: 400; }

.text-extra-light {
  font-weight: 200; }

.heading-x-large {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 2.375em;
  font-weight: 700;
  line-height: 1.25em; }

.heading-large {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 2.25em;
  font-weight: 600;
  line-height: 1.25em; }

.heading-medium {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 2.125em;
  font-weight: 500;
  line-height: 1.25em; }

.heading-small {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 2em;
  font-weight: 500;
  line-height: 1.25em; }

.title-text {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 1.75em;
  font-weight: 700;
  line-height: 1.5em; }

.label-text {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em; }

.subLabel-text {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 0.85em;
  font-weight: 700;
  color: #8c8c8c;
  line-height: 1.5em; }

.body-text {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.1em;
  margin: 0;
  padding: 0.75em 0; }

.caption-text {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1.25em;
  margin: 0.5em 0; }

.default-font {
  font-family: Montserrat, 'Helvetica Neue', Arial; }

.avatar {
  display: inline-flex;
  align-items: center; }

.avatar-text {
  padding-left: 0.25em; }

.badge-input {
  font-size: 0.8871em;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.35em;
  margin: 10px 4px;
  border: none;
  display: inline-flex;
  align-items: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 5px 5px 25px -8px #8a8a8a; }

.badge-small {
  font-size: 0.8em;
  padding: 0px 10px;
  height: 25px;
  border-radius: 15px; }

.badge-medium {
  font-size: 0.9em;
  padding: 0px 12px;
  height: 30px;
  border-radius: 15px; }

.badge-large {
  font-size: 1em;
  padding: 0px 16px;
  height: 35px;
  border-radius: 25px; }

.breadcrumb-nav {
  display: flex;
  align-items: center; }

.breadcrumb-nav li {
  display: flex; }

.breadcrumb-nav li a {
  text-decoration: unset;
  color: #000000; }

.breadcrumb-nav li a:hover {
  color: #000000; }

.current-crumb {
  color: #8c8c8c; }

.history-crumb,
.current-crumb {
  display: flex;
  align-items: center; }

.history-crumb h2,
.current-crumb h2 {
  padding: 0 0.25em; }

.history-crumb a {
  text-decoration: unset;
  color: #000000; }

.home-crumb {
  padding-left: 0.5em; }

.calloutBanner {
  padding: 0.75em;
  color: black; }

.card {
  box-shadow: 5px 5px 15px 0px #c8c8c8;
  border-radius: 5px;
  padding: 0.75em;
  background: white; }

.cardHeader {
  font-family: Montserrat, 'Helvetica Neue', Arial;
  font-weight: 400;
  font-size: 1.5em; }

.cardFooter {
  font-family: Montserrat, 'Helvetica Neue', Arial;
  font-weight: 300;
  font-size: 1.25em;
  text-align: right; }

.definition-row {
  display: flex;
  padding: 1.5em 0;
  border-top: solid 1px #8c8c8c;
  font-size: 0.85em; }

.definition-row:first-child {
  border: none; }

.definition-table {
  min-width: 750px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background: #8c8c8c; }

.definition-name {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 225px;
  font-weight: 700;
  padding-left: 1.75em; }

.definition-defined {
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 1.75em; }

.definition-title {
  text-transform: capitalize;
  margin-bottom: 0.25em; }

.definitions-table .body-text {
  padding-top: 0; }

.definition-row:hover {
  background: #e6e6e6; }

.horizontal-tab-width {
  padding: 0; }

.tabs {
  display: flex;
  justify-content: space-between; }

.active-tab {
  border-bottom: 3px solid blue; }

.tab-name {
  text-align: center;
  padding: 1em;
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
  cursor: pointer; }

.active-tab-body {
  display: inline-block;
  width: 100%; }

.nonactive-tab-body {
  display: none; }

.list {
  padding: 0.15em 0; }

.unbulleted {
  list-style: none;
  padding-left: 0; }

.list-style {
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 1em;
  font-weight: 600; }

.toggle {
  display: inline-flex;
  align-items: center; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 24px;
  margin: 10px 5px 10px 10px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.before {
  position: absolute;
  content: '';
  height: 34px;
  width: 34px;
  left: -2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 2px 5px 15px 2px #949294; }

input:not(:checked) + .default {
  background-color: #ffffff; }
  input:not(:checked) + .default .before {
    color: #000000; }

input:not(:checked) + .primary {
  background: #000be6; }
  input:not(:checked) + .primary .before {
    color: #0000ff; }

input:not(:checked) + .info {
  background: #00e1ff; }
  input:not(:checked) + .info .before {
    color: #00a9bf; }

input:not(:checked) + .success {
  background: #1bff00; }
  input:not(:checked) + .success .before {
    color: #13bf00; }

input:not(:checked) + .warning {
  background: #fff000; }
  input:not(:checked) + .warning .before {
    color: #fff000; }

input:not(:checked) + .danger {
  background: #ff1400; }
  input:not(:checked) + .danger .before {
    color: #bf1000; }

input:checked + .default {
  background-color: #000000; }
  input:checked + .default .before {
    background: #000000;
    box-shadow: 2px 5px 15px 2px #949294;
    -webkit-transform: translateX(22px);
    transform: translateX(22px); }

input:checked + .primary {
  background: #0000ff; }
  input:checked + .primary .before {
    background: #000680;
    box-shadow: 2px 5px 15px 2px #949294;
    -webkit-transform: translateX(22px);
    transform: translateX(22px); }

input:checked + .info {
  background: #00a9bf; }
  input:checked + .info .before {
    background: #007180;
    box-shadow: 2px 5px 15px 2px #949294;
    -webkit-transform: translateX(22px);
    transform: translateX(22px); }

input:checked + .success {
  background: #13bf00; }
  input:checked + .success .before {
    background: #000680;
    box-shadow: 2px 5px 15px 2px #949294;
    -webkit-transform: translateX(22px);
    transform: translateX(22px); }
  input:checked + .success + .toggleLabel {
    color: #13bf00; }

input:checked + .warning {
  background: #fff000; }
  input:checked + .warning .before {
    background: #808000;
    box-shadow: 2px 5px 15px 2px #949294;
    -webkit-transform: translateX(22px);
    transform: translateX(22px); }

input:checked + .danger {
  background: #bf1000; }
  input:checked + .danger .before {
    background: #800b00;
    box-shadow: 2px 5px 15px 2px #949294;
    -webkit-transform: translateX(22px);
    transform: translateX(22px); }

/* Rounded sliders */
.round {
  border-radius: 34px;
  display: flex;
  align-items: center; }

.round.before {
  border-radius: 50%; }

.toggleLabel {
  font-family: Montserrat, 'Helvetica Neue', Arial; }

.round .nc-icon {
  text-align: center; }

.vertical-tab-width {
  padding: 0;
  margin-bottom: 250px;
  display: flex;
  flex-flow: row; }

.vertical-tabs {
  display: flex;
  width: 25%;
  flex-flow: column;
  justify-content: space-between; }

.vertical-active-tab {
  border-bottom: 3px solid blue; }

.vertical-tab-name {
  text-align: center;
  padding: 1em;
  font-family: Montserrat, "Helvetica Neue", Arial;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em; }

.vertical-active-tab-body {
  display: inline-block;
  padding: 1em; }

.vertical-nonactive-tab-body {
  display: none; }

