$primary-color: #0000ff;
$primary-hover-color: #000abf;
$primary-dark-color: #000680;
$primary-light-color: #000be6;

$secondary-color: #606060;
$secondary-hover-color: #6e6e6e;
$secondary-dark-color: #474747;
$secondary-light-color: #adadad;
$secondary-ultralight-color: #ededed;
$info-color: #00a9bf;
$info-hover-color: #00cbe6;
$info-dark-color: #007180;
$info-light-color: #00e1ff;

$success-color: #13bf00;
$success-hover-color: #17e600;
$success-dark-color: #0d8000;
$success-light-color: #1bff00;

$warning-color: #fff000;
$warning-hover-color: #e6e600;
$warning-dark-color: #808000;
$warning-light-color: #fff000;

$danger-color: #bf1000;
$danger-hover-color: #e61300;
$danger-dark-color: #800b00;
$danger-light-color: #ff1400;

$dark-color: #000000;
$dark-hover-color: #4d4d4d;
$dark-light-color: #8c8c8c;

$light-color: #ffffff;
$light-hover-color: #e6e6e6;
$light-dark-color: #b8b7b7;
