@import '../../styles/scss/colors.scss';

.breadcrumb-nav {
  display: flex;
  align-items: center;
}

.breadcrumb-nav li {
  display: flex;
}

.breadcrumb-nav li a {
  text-decoration: unset;
  color: $dark-color;
}

.breadcrumb-nav li a:hover {
  color: $dark-color;
}

.current-crumb {
  color: $dark-light-color;
}

.history-crumb,
.current-crumb {
  display: flex;
  align-items: center;
}

.history-crumb h2,
.current-crumb h2 {
  padding: 0 0.25em;
}

.history-crumb a {
  text-decoration: unset;
  color: $dark-color;
}

.home-crumb {
  padding-left: 0.5em;
}
