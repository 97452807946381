@import '../../styles/scss/colors';
@import '../../styles/scss/typography';

.horizontal-tab-width {
  padding: 0;
}

.tabs {
  display: flex;
  justify-content: space-between;
}

.active-tab {
  border-bottom: 3px solid blue;
}
.tab-name {
  text-align: center;
  padding: 1em;
  font-family: $font-style;
  font-size: $font-label;
  font-weight: $font-weight-heavy;
  line-height: 1em;
  cursor: pointer;
}

.active-tab-body {
  display: inline-block;

  width: 100%;
}
.nonactive-tab-body {
  display: none;
}
